<template>
   <div class="wrapper">
     <headBack>
       <template v-slot:title>
         <div class='title'>
           订单详情
         </div>
       </template>
     </headBack>
     <div class='header_cont'></div>
     <div class="list_box">
       <div class="top">
         <div>快天使医护到家商城</div>
         <div class="timer">{{ detail.orderStatusDesc}}</div>
       </div>
       <van-card
           v-for="(item,index) in detail.orderData"
           :num="item.goodsNum"
           :price="item.goodsPrice"
           desc=""
           :title="item.goodsName"
           :thumb="item.iconUrl"
       />
       <div class="total_box">
         <div>总价：<span class="jg">¥{{detail.orderPriceSum}}</span></div>
       </div>
     </div>
     <div class="list_box cont_box">
          <div class="co_box list"><div>订单编号：<span>{{detail.orderId}}</span></div> <div @click="copy(detail.orderId)">复制</div></div>
          <div class="list">下单时间：<span>{{detail.createTimeDesc}}</span></div>
          <div class="list">支付时间：<span>{{detail.payTimeDesc}}</span></div>
          <div class="list">支付方式：<span>{{detail.payChannel}}</span></div>
     </div>
     <div class="list_box cont_box">
       <div class="list">配送方式：<span></span></div>
       <div class="list address">
            <div>收货地址：</div>
            <div class="cont">
                {{address.consignee}} {{ address.contactNumber }} <br>{{address.detailedAddress + address.addressDetail}}
            </div>

       </div>
     </div>
     <div class="list_box cont_box">
       <div class="list jge">商品总额：<span>+{{detail.orderPriceSum}}</span></div>
       <div class="list jge">运费：<span>0.00</span></div>
       <div class="t_price">实付款：<span>¥<span class="num">{{detail.payPriceSum}}</span></span></div>
     </div>
   </div>
</template>

<script>
import {setWxShareDate} from '@/libs/utils.js'
import {onMounted, ref, computed, reactive, watch} from "vue"
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import {Dialog,Toast} from 'vant'
import shopApi from '@axios/shop'
import loginApi from '@axios/login'
export default {
  name: "shopOrderDetail",
  setup(){

    const route = useRoute();
    const store = useStore();
    const detail = ref('')
    const getDetail = () => {
         const params={
            orderId:route.query.orderId
         }
      shopApi.getOrderDetail(params).then((res)=> {
          console.log(res)
          detail.value = res.data;
        getAddress()
      })
    }
    getDetail()

    const address = ref('')
    const getAddress = ()=> {
      const params ={
        userId: store.state.uid,
        addressId : detail.value.addressId
      }
      shopApi.getAddressDetail(params).then((res)=> {
        console.log(res)
        address.value = res.data
      })
    }

    const copy= (txt)=> {
        let input = document.createElement("input"); // 创建input对象
        input.value = txt; // 设置复制内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例

        Toast("复制成功")
    }
    return {
      getDetail,
      detail,
      copy,
      getAddress,
      address
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper{
  padding-bottom: 60px;
}
.list_box{
  color:#323232;
  width: 94%;
  margin:20px auto;
  background: #fafafa;
  border-radius: 20px;
  padding:20px 0px;
  .top{
    font-size: 28px;
    padding:0 30px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    .timer{
      color:#999;
      display: flex;
      font-size: 24px;
      align-items: center;
    }
  }
  .total_box{
    margin:10px 0;
    padding-right: 30px;
    text-align: right;
    font-size: 26px;
    .jg{
      font-size: 32px;
    }

  }


}
.cont_box{
  box-sizing: border-box;
  color:#878888;
  font-size: 26px;
  .co_box{
    display: flex;
    justify-content: space-between;
  }
  .list {
    padding:0 30px;
    margin-bottom: 16px;
    span{
      color:#1d1e1e;
    }
  }
  .list:last-child{
    margin-bottom: 0!important;
  }
  .address{
    display: flex;
    .cont{
       color:#1d1e1e
    }
  }
  .jge{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    span{
      color:#f2270c;
      font-size: 28px;
    }
  }
  .t_price{
    span{
      color:#f2270c
    }
    font-weight: 500;

     padding:0 30px;
     display: flex;
     justify-content: flex-end;
    .num{
       font-size: 32px;
    }
  }
}
</style>
